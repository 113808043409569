<template>
  <div class="userexpress-create">
    <ui-wizard
      :title="$t('userexpress.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
      :is-valid="!$v.$error"
    >
      <ui-wizard-step :title="$t('userexpress.create.step.config')" :before-change="checkUserExpress">
        <div class="userexpress-create__wrapper">
          <ui-input
            class="userexpress-create__wrapper__input"
            v-model.trim="firstname"
            :label="$t('userexpress.create.firstname')"
            id="userexpress-firstname"
            :error="$v.firstname.$error"
            :is-required="true"
          >
            <template v-if="$v.firstname.$error && !$v.firstname.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <ui-input
            class="userexpress-create__wrapper__input"
            v-model.trim="lastname"
            :label="$t('userexpress.create.lastname')"
            id="userexpress-lastname"
            :error="$v.lastname.$error"
            :is-required="true"
          >
            <template v-if="$v.lastname.$error && !$v.lastname.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <ui-input
            class="userexpress-create__wrapper__mail"
            v-model.trim="mail"
            :label="$t('userexpress.create.mail')"
            id="userexpress-mail"
            :error="$v.mail.$error"
            :is-required="true"
          >
            <template v-if="$v.mail.$error && !$v.mail.required">
              {{ $t('errors.required') }}
            </template>
            <template v-else-if="$v.mail.$error && !$v.mail.email">
              {{ $t('errors.email') }}
            </template>
          </ui-input>
          <ui-tag
            class="userexpress-create__wrapper__locationsLinked"
            v-model.trim="locationsLinked"
            id="userexpress-locations"
            track-by="id"
            :options="locations"
            :tag-label="$t('userexpress.create.locations.label')"
            :error="$v.locationsLinked.$error"
            :is-required="true"
            :placeholder="$t('userexpress.create.locations.placeholder')"
            :show-label="true"
            :no-absolute="true"
            label="name"
            :clear-on-select="false"
            @select="setTags"
            @search="searchLocations"
          >
            <template v-slot:tag="{ tag }">
              <span class="ui-tag__multiselect__tag__label">
                {{ tag.group ? `[${tag.group}]` : '' }} {{ tag.name }}
              </span>
            </template>
            <template v-slot:option="{ option }">
              <span v-if="option.isTag">{{ option.label }}</span>
              <span v-else>{{ option.group ? `[${option.group}]` : '' }} {{ option.name }}</span>
            </template>
            <template v-slot:helper v-if="$v.locationsLinked.$error && !$v.locationsLinked.required">
              {{ $t('errors.required') }}
            </template>
          </ui-tag>
        </div>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('userexpress.create.step.summary')">
        <div class="userexpress-create__grid">
          <screen-block
            :title="$t('userexpress.create.label.firstname')"
            icon="user"
            :has-data="firstname !== '' && typeof firstname === 'string'"
            :data="firstname"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('userexpress.create.label.lastname')"
            icon="user"
            :has-data="lastname !== '' && typeof lastname === 'string'"
            :data="lastname"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('userexpress.create.label.mail')"
            icon="mail"
            :has-data="mail !== '' && typeof mail === 'string'"
            :data="mail"
            ratio="1-1"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('userexpress.create.label.locations')"
            icon="business"
            :has-data="locationsLinked !== ''"
            :data="locationsLinked"
            ratio="1-1"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <span v-for="(location, idxList, idx) in locationsLinked" :key="idx">
                {{ location.name }}<template v-if="idxList < locationsLinked.length - 1">, </template>
              </span>
            </template>
          </screen-block>
        </div>
      </ui-wizard-step>
    </ui-wizard>
  </div>
</template>

<script>
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'UserExpressCreate',
  components: {
    UiWizard,
    UiWizardStep,
    UiInput,
    UiTag,
    ScreenBlock,
  },
  mixins: [notif],
  data() {
    return {
      lastname: '',
      firstname: '',
      mail: '',
      locationsLinked: [],
      isSaving: false,
      wizardIndex: 0,
      search: '',
    }
  },
  async created() {
    await this.getLocations({ tableParams: { mode: 'userexpress' } })
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('userexpress.list.breadcrumb'),
          route: {
            name: 'UserExpress',
          },
        },
        {
          label: this.$t('userexpress.create.breadcrumb'),
          route: {
            name: 'UserExpressCreate',
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
      createUserExpress: 'userexpress/createUserExpress',
    }),
    onBack() {
      this.$router.push({ name: 'UserExpress' })
    },
    onComplete() {
      this.isSaving = true
      this.createUserExpress({
        lastName: this.lastname,
        firstName: this.firstname,
        email: this.mail,
        locations: this.locationsLinked.map(locations => locations.id),
      })
        .then(response => {
          this.notificationSuccess('userexpress', `${this.firstname} ${this.lastname}`, 'create')
          this.$router.push({ name: 'UserExpressId', params: { id: response.id } })
          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
          this.notificationError()
        })
    },
    setTags(tags) {
      this.locationsLinked = tags
    },
    searchLocations(e) {
      this.search = e
      this.getLocations({ tableParams: null, search: this.search })
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkUserExpress() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
  },
  validations() {
    return {
      lastname: {
        required,
      },
      firstname: {
        required,
      },
      mail: {
        required,
        email,
      },
      locationsLinked: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.userexpress-create {
  &__wrapper {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__input {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        width: calc(100% / 2 - #{$gutter-tablet});
      }

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }
    }

    &__mail,
    &__locationsLinked {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        width: 100%;
      }

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }
    }
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet / 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop / 2);
    }
  }
}
</style>
